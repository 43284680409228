<template>
  <div
    ref="wrapper"
    class="sticky-wrapper"
    :class="{
      'is-history': props.type === 'history',
      'is-placeholder': props.type === 'placeholder',
      'is-item': props.type === 'item',
    }"
    :style="{ 'clip-path': polygon }"
  >
    <div class="border" />
    <slot />
  </div>
</template>

<script setup>
import { useElementSize } from '@vueuse/core';
import { ref, computed } from 'vue';

const wrapper = ref(null);
const { width } = useElementSize(wrapper);

const position = computed(() => (`${width.value - 14}px`));

const polygon = computed(() => `polygon(-1% -3%, ${position.value} -3%, ${position.value} 0, 100% 14px, 101% 14px, 101% 103%, -1% 103%)`);

const props = defineProps({
  type: {
    type: String,
    default: 'placeholder',
    validator(value) {
      return ['placeholder', 'item', 'history'].includes(value);
    },
  },
});
</script>

<style lang="scss" scoped>
.sticky-wrapper {
  position: relative;
  border-radius: 4px;
  @include transition-base('background');

  &::after {
    content: '';
    background: var(--color-D8D8D8);
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 0 0 2px;
    @include transition-base('background');
  }

  .border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    pointer-events: none;
    border-width: 1px;
    border-style: solid;
  }

  &.is-placeholder {
    background: var(--color-F5F5F5);

    .border {
      border-color: var(--color-black-005);
    }

    &:hover {
      background: var(--color-F0F0F0);
      .border {
        border-color: var(--color-black-01);
      }

      &::after {
        background: var(--color-D4D4D4);
      }
    }
  }

  &.is-item {
    background: var(--color-white);

    .border {
      border-color: var(--color-E8E8E8);
    }
  }

  &.is-history {
    background: var(--color-F7F7F7);

    .border {
      border-color: var(--color-black-005);
    }
  }
}
</style>
