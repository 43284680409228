<template>
  <SmoothModal
    v-model="value"
    width="660px"
    :has-header="false"
    @open="onOpenModal"
    @update:model-value="onCloseModal"
  >
    <div class="add-sticky-modal">
      <AppText
        size="18px"
        class="font-medium"
        variant="div"
        mb="8px"
      >
        Post sticky
      </AppText>
      <AppText variant="div" mb="20px" opacity="0.5">
        Incident stickies have permanent visibility while News stickies can be hidden once viewed by the audience, post accordingly.
      </AppText>

      <ActionForm
        :request-fields="requestFields"
        :validation-schema="validationSchema"
        :response-errors="fieldsErrors"
        @validate="onPassedValidation"
      >
        <template #default="{ errors, action }">
          <FRadioButton
            v-model="requestFields.type"
            :options="options"
            height="32px"
            @change="initData"
          />

          <FInput
            v-model="requestFields.title"
            size="small"
            type="textarea"
            placeholder="Enter announcement title"
            :validation-error="errors.title"
            autofocus
            :maxlength="100"
            show-word-limit
          />

          <FInput
            v-model="requestFields.url"
            placeholder="https://docs.switch.to/..."
            :validation-error="errors.url"
            size="small"
          >
            <template #prefix>
              <AppIcon
                name="globe-world"
                opacity="0.4"
                size="18px"
                is-img-tag
              />
            </template>
          </FInput>

          <div class="audience">
            <div class="d-flex align-items-center" style="gap: 24px;">
              <AppText>Audience:</AppText>
              <AppTooltip
                text-align="left"
                cursor="default"
                width="264px"
                :is-enabled="!isVisitorsAudienceAvailable"
              >
                <template #text>
                  Another sticky with this audience is already active
                </template>
                <FCheckbox
                  v-model="isVisitors"
                  label="Visitors"
                  :disabled="!isVisitorsAudienceAvailable"
                />
              </AppTooltip>
              <AppTooltip
                text-align="left"
                cursor="default"
                width="264px"
                :is-enabled="!isMerchantsAudienceAvailable"
              >
                <template #text>
                  Another sticky with this audience is already active
                </template>
                <FCheckbox
                  v-model="isMerchants"
                  label="Merchants"
                  :disabled="!isMerchantsAudienceAvailable"
                />
              </AppTooltip>
            </div>
            <AppText v-if="errors?.audience" color="var(--color-error)" variant="div" mt="8px">
              {{ errors?.audience?.message }}
            </AppText>
          </div>

          <div class="d-flex align-items-center expire" style="gap: 24px;">
            <AppText>Expire in:</AppText>
            <FRadio v-model="requestFields.revoke" :options="periods" />
          </div>

          <FButton type="primary" @click="action">
            Post sticky
          </FButton>
        </template>
      </ActionForm>
    </div>
  </SmoothModal>
</template>

<script>
import { computed, ref, watch } from 'vue';

import SmoothModal from '@/components/SmoothModal';
import AppTooltip from '@/components/AppTooltip.vue';

import { addSticky } from '@/api/admin/stickies';
import { useModel } from '@/composables/useModel';
import { useStickies } from '@/composables/useStickies';
import {
  ActionForm, memoRule, useValidation, yup, docUrlRule,
} from '@/validation';

export default {
  name: 'ModalToAddSticky',
  components: {
    SmoothModal,
    ActionForm,
    AppTooltip,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { value } = useModel(props, emit);
    const { active } = useStickies();

    const onCloseModal = () => {
      emit('update:modelValue', false);
      clearState();
    };

    const isVisitors = ref(true);
    const isMerchants = ref(false);

    const getAudience = () => {
      if (isVisitors.value && isMerchants.value) {
        return ['VISITORS', 'MERCHANTS'];
      }
      if (isVisitors.value) {
        return ['VISITORS'];
      }
      if (isMerchants.value) {
        return ['MERCHANTS'];
      }
      return [];
    };

    const isVisitorsAudienceAvailable = computed(() => !active?.value[0]?.audience?.includes('VISITORS'));
    const isMerchantsAudienceAvailable = computed(() => !active?.value[0]?.audience?.includes('MERCHANTS'));

    const type = ref('NEWS');

    const options = [
      { text: 'News', value: 'NEWS' },
      { text: 'Incident', value: 'INCIDENT' },
    ];

    const periods = [
      { text: '1 Day', value: 'DAY' },
      { text: '3 Days', value: 'THREE_DAYS' },
      { text: '1 Week', value: 'WEEK' },
      { text: '1 Month', value: 'MONTH' },
    ];

    const fields = {
      audience: getAudience(),
      type: options[0].value,
      title: '',
      url: '',
      revoke: periods[0].value,
    };

    const { initState, fieldsErrors, clearState } = useValidation();

    const validationSchema = computed(() => yup.object().shape({
      audience: yup.array().min(1, 'Please select at least one available audience'),
      title: memoRule({ max: 100 }),
      url: docUrlRule(),
    }));

    const { requestFields } = initState(fields);

    watch([isVisitors, isMerchants], () => {
      requestFields.audience = getAudience();
    });

    const onPassedValidation = async () => {
      addSticky({ ...requestFields });
      onCloseModal();
    };

    const initData = () => {
      isVisitors.value = isVisitorsAudienceAvailable.value;
      isMerchants.value = !isVisitors.value;
      type.value = options[0].value;
      requestFields.title = '';
      requestFields.url = '';
      requestFields.revoke = periods[0].value;
    };

    const onOpenModal = () => {
      initData();
    };

    return {
      value,
      onCloseModal,
      options,
      type,
      onPassedValidation,
      requestFields,
      validationSchema,
      fieldsErrors,
      periods,
      isVisitors,
      isMerchants,
      isVisitorsAudienceAvailable,
      isMerchantsAudienceAvailable,
      onOpenModal,
      initData,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-sticky-modal {
  padding-top: 30px;

  :deep(.f-radio-button), :deep(.f-input) {
    margin-bottom: 20px;
  }

  .audience {
    margin-bottom: 16px;
  }

  .expire {
    margin-bottom: 24px;
  }

  :deep(.el-checkbox) {
    margin-right: 0;
  }
}
</style>
