<template>
  <CustomScroll style="height: calc(100vh - 354px);" @scroll-end="onGetMoreHistory">
    <div class="history">
      <div class="header">
        <AppText variant="div" size="18px" class="font-medium" @click="onGetMoreHistory">
          History
        </AppText>
        <FSelect
          v-model="selectedYear"
          :options="yearsOptions"
          has-arrow
          no-margin
          size="x-small"
          @change="onYearChange"
        />
      </div>
      <transition name="show">
        <div v-if="currentHistory.length && isHistoryLoaded" class="items">
          <HistoryItem v-for="item in currentHistory" :key="item" :item="item" />
        </div>

        <NoHistory v-else-if="!currentHistory.length && isHistoryLoaded" />
      </transition>
    </div>
  </CustomScroll>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import dayjs from 'dayjs';

import { getStickiesHistory, getStartedYear } from '@/api/admin/stickies';
import { useStickies } from '@/composables/useStickies';
import { useStickiesStore } from '@/store';

import HistoryItem from './components/HistoryItem.vue';
import NoHistory from './components/NoHistory.vue';

const { setLoadedState, clearHistory } = useStickiesStore();
const {
  history, isHistoryLoaded, stickiesStartingYear, stickiesSelectedYear, historyPagination,
} = useStickies();

const selectedYear = ref(stickiesSelectedYear.value);

const currentHistory = computed(() => history.value.filter((item) => dayjs(item.createdAt).year() === selectedYear.value));

const yearsOptions = computed(() => {
  const years = [];

  for (let i = stickiesStartingYear.value; i <= dayjs().year(); i++) {
    years.push({ label: i, value: i });
  }

  return years.reverse();
});

const onYearChange = (year) => {
  clearHistory();
  setLoadedState(false);
  getStickiesHistory({ year }).then(() => {
    setLoadedState(true);
  });
};

const onGetMoreHistory = () => {
  const nextPage = historyPagination.value.page + 1;

  if (nextPage <= historyPagination.value.pageCount) {
    getStickiesHistory({
      year: selectedYear.value,
      page: nextPage,
    });
  }
};

onBeforeMount(async () => {
  isHistoryLoaded.value = false;
  clearHistory();
  await getStartedYear();
  await getStickiesHistory({
    year: dayjs().year(),
  });
  setLoadedState(true);
});
</script>

<style lang="scss" scoped>
.history {
  max-width: 980px;
  padding: 40px;

  .header {
    height: 36px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    :deep(.f-select--wrapper) {
      width: 90px;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
