<template>
  <StickyWrapper type="history">
    <div class="item">
      <div class="body">
        <div class="link">
          <ExternalLink
            size="15px"
            :line-height="1.5"
            color="var(--color-black-06)"
            :has-prepend="false"
            :href="item.url"
            is-append-hover
          >
            {{ item.title }}
          </ExternalLink>
        </div>
        <div class="events">
          <div v-if="item.expirationReason === 'AUTOMATIC'" class="event">
            <DotSeparator color="var(--color-black-06)" />
            <div class="date-wrap">
              <DateFormatter :date="item.revokeTime" /> at <DateFormatter :date="item.revokeTime" :format="timeFormat" />
            </div>
            <AppText :opacity="0.6">
              Expired automatically after {{ expired || 1 }} {{ expired > 1 ? 'days' : 'day' }}
            </AppText>
          </div>

          <div v-else class="event">
            <DotSeparator color="var(--color-black-06)" />
            <div class="date-wrap">
              <DateFormatter :date="item.expiredAt" /> at <DateFormatter :date="item.expiredAt" :format="timeFormat" />
            </div>
            <AppText :opacity="0.6">
              Retired manually by
            </AppText>
            <AppAvatar
              :key="item.retiredBy?.profilePicture?.cropUrl"
              :src="item.retiredBy?.profilePicture?.cropUrl"
              is-hover-disabled
              is-badge-disabled
              size="18px"
            />
            <AppText :opacity="0.6">
              {{ item.retiredBy?.username }}
            </AppText>
          </div>

          <div class="event">
            <DotSeparator color="var(--color-black-06)" />
            <div class="date-wrap">
              <DateFormatter :date="item.createdAt" /> at <DateFormatter :date="item.createdAt" :format="timeFormat" />
            </div>
            <AppText :opacity="0.6">
              Created by
            </AppText>
            <AppAvatar
              :key="item.createdBy?.profilePicture?.cropUrl"
              :src="item.createdBy?.profilePicture?.cropUrl"
              is-hover-disabled
              is-badge-disabled
              size="18px"
            />
            <AppText :opacity="0.6">
              {{ item.createdBy?.username }}
            </AppText>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-item">
          <AppIcon :name="stickiesTypes[item.type].icon" size="14px" :opacity="stickiesTypes[item.type].value === 'NEWS' ? 0.4 : 1" is-img-tag />
          <AppText size="12px" color="var(--color-black-06)" class="font-medium">
            {{ stickiesTypes[item.type].text }}
          </AppText>
        </div>
        <div class="footer-item">
          <AppIcon name="clock" size="14px" :opacity="0.4" is-img-tag />
          <AppText size="12px" color="var(--color-black-06)" class="font-medium">
            {{ duration || 1 }} {{ duration > 1 ? 'days' : 'day' }}
          </AppText>
        </div>
        <div class="footer-item">
          <AppIcon name="eye-view" size="14px" :opacity="0.4" is-img-tag />
          <AppText size="12px" color="var(--color-black-06)" class="font-medium is-first-letter-capitalized">
            {{ audience }}
          </AppText>
        </div>
      </div>
    </div>
  </StickyWrapper>
</template>

<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';

import AppAvatar from '@/components/Avatars/AppAvatar.vue';
import DotSeparator from '@/components/DotSeparator.vue';
import ExternalLink from '@/components/ExternalLink.vue';

import { stickiesTypes } from '@/common/data';
import { useProfile } from '@/composables/useProfile';

import StickyWrapper from '../../StickyWrapper.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const duration = computed(() => dayjs(props.item.revokeTime).diff(props.item.createdAt, 'day'));
const expired = computed(() => dayjs(props.item.expiredAt).diff(props.item.createdAt, 'day'));
const audience = computed(() => props.item.audience.map((item) => item).join(' and '));

const { timeFormat } = useProfile();
</script>

<style lang="scss" scoped>
.item {
  padding: 0 20px;

  .body {
    padding: 20px 0 24px;

    .link {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 16px;

      :deep(.text-link) {
        @include font-medium;
      }
    }

    .events {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .event {
        display: flex;
        align-items: center;

        .date-wrap {
          font-size: 13px;
          margin-right: 20px;
          color: var(--color-black-06);

          :deep(.date) {
            color: var(--color-black-06);
          }
        }

        :deep(.dot) {
          margin: 0 16px 0 0;
        }

        :deep(.avatar) {
          margin: 0 8px;
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    gap: 26px;
    padding: 0 8px;
    height: 50px;
    border-top: 1px solid var(--color-black-005);

    .footer-item {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
</style>
