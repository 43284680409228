<template>
  <StickyWrapper
    class="sticky-item"
    :class="{ news: item.type === 'NEWS', incident: item.type === 'INCIDENT' }"
    type="item"
  >
    <div class="line" />
    <div class="header">
      <div class="header-item">
        <AppIcon name="eye-view" size="14px" :opacity="0.4" is-img-tag />
        <AppText size="12px" color="var(--color-black-06)" class="font-medium">
          {{ audience }}
        </AppText>
      </div>

      <FDropdown placement="bottom-end" class="retired-dropdown">
        <template #title>
          <AppIcon name="options" size="18px" :opacity="0.4" is-img-tag />
        </template>

        <template #content>
          <DropdownItem size="medium" no-paddings @click="onRetire">
            <AppIcon name="check-done" size="16px" fill="var(--color-black-04)" style="margin-right: 12px; margin-top: 1px;" />
            <AppText pr="4px">
              Retire Early
            </AppText>
          </DropdownItem>
        </template>
      </FDropdown>
    </div>
    <div class="body">
      <ExternalLink
        :has-prepend="false"
        :href="item.url"
        is-append-hover
      >
        <AppText
          size="15px"
          class="font-medium"
        >
          {{ item.title }}
        </AppText>
      </ExternalLink>
    </div>
    <div class="footer">
      <AppAvatar
        :key="item.createdBy?.profilePicture?.cropUrl"
        :src="item.createdBy?.profilePicture?.cropUrl"
        is-hover-disabled
        is-badge-disabled
        size="18px"
      />
      <AppText style="flex-grow: 1;">
        {{ item.createdBy?.username }}
      </AppText>
      <AppText :opacity="0.6">
        Expires in <DateDurationWithNow :date="item.revokeTime" no-styles />
      </AppText>
      <AppTooltip
        text-align="center"
        cursor="default"
      >
        <template #text>
          Posted <DateDurationWithNow :date="item.createdAt" is-from no-styles /> ago
        </template>
        <AppIcon
          name="info-circle-medium"
          size="18px"
          :opacity="0.4"
          is-img-tag
        />
      </AppTooltip>
    </div>
  </StickyWrapper>
</template>

<script setup>
import { computed } from 'vue';

import AppAvatar from '@/components/Avatars/AppAvatar.vue';
import DateDurationWithNow from '@/components/DateDurationWithNow.vue';
import DropdownItem from '@/components/ElementUI/DropdownItem.vue';
import ExternalLink from '@/components/ExternalLink.vue';
import AppTooltip from '@/components/AppTooltip.vue';

import { makeFirstLetterUppercased } from '@/utils/functions';
import { retireSticky } from '@/api/admin/stickies';

import StickyWrapper from '../../StickyWrapper.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const audience = computed(() => props.item.audience.map((item) => makeFirstLetterUppercased(item)).reverse().join(' and '));

const onRetire = () => {
  // eslint-disable-next-line no-underscore-dangle
  retireSticky(props.item._id);
};
</script>

<style lang="scss" scoped>
.sticky-item {
  width: 480px;
  height: 193px;
  position: relative;

  &.news {
    .line {
      position: absolute;
      width: 100%;
      height: 3px;
      background: var(--color-E3BB9A);
      border-radius: 4px 0 0 0;
    }

    &::after {
      background-color: var(--color-primary);
    }
  }

  &.incident {
    background: var(--color-FFFAFA);

    .line {
      position: absolute;
      width: 100%;
      height: 3px;
      background: var(--color-error);
      border-radius: 4px 0 0 0;
    }

    &::after {
      background-color: var(--color-error);
    }
  }

  .header {
    border-bottom: 1px solid var(--color-E8E8E8);
    height: 50px;
    padding: 3px 18px 0 30px;
    display: flex;
    align-items: center;

    .header-item {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-grow: 1;
    }

    .retired-dropdown {
      width: 36px;
      height: 36px;

      :deep(.f-dropdown--title) {
        width: 36px;
        height: 36px;
        padding: 0;
        @include flex-center;
      }
    }
  }

  .body {
    height: calc(100% - 110px);
    display: flex;
    padding: 24px 28px;

    :deep(.external-link .text-link .text) {
      color: var(--color-black);
      @include transition-base('color');
    }

    :deep(.external-link:hover .text-link .text) {
      color: var(--color-primary);
    }
  }

  .footer {
    height: 60px;
    padding: 0 28px;
    display: flex;
    align-items: center;
    gap: 8px;

    :deep(.items) {
      font-size: 13px;
    }
  }
}
</style>
