<template>
  <div class="no-history">
    <div class="icon-wrap">
      <AppIcon name="pin" size="22px" />
    </div>
    <AppText size="16px" :opacity="0.5">
      Stickies will appear here after expiration.
    </AppText>
  </div>
</template>

<script>
export default {
  name: 'NoHistory',
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.no-history {
  max-width: 900px;
  min-height: 200px;
  padding: 100px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-wrap {
    background: var(--color-F7F7F7);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 20px;
    @include flex-center;

    :deep(.icon path) {
      fill: var(--color-CCCCCC);
    }
  }
}
</style>
